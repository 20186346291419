<template>
  <qlHeader theme="white">
    <template slot="list">
      <!-- 202205版本 屏蔽离线升级入口 -->
      <!-- <div
        v-if="isSchoolSuper"
        class="item flex items-center"
        :class="{ 'cursor-not-allowed': isUpdating, 'cursor-pointer': !isUpdating }"
        @click="handleBackup"
      >
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_daohang_shujukubeifen"></use>
        </svg>
        <p>数据库备份</p>
      </div>
      <div
        v-if="isSchoolSuper"
        class="item flex items-center"
        :class="{ 'cursor-not-allowed': isBackuping, 'cursor-pointer': !isBackuping }"
        @click="handleUpgrade"
      >
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_daohang_lixianshengji"></use>
        </svg>
        <p>离线升级</p>
      </div> -->

      <div v-if="importDataShow" class="item flex items-center cursor-pointer" @click="syncVisible = true">
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_daohang_shujutongbu"></use>
        </svg>
        <p>安徽省平台数据同步</p>
      </div>

      <div v-if="importDataShow" class="item flex items-center cursor-pointer" @click="handleAllConnect">
        <svg class="mg-r-10 fz-20 icon" aria-hidden="true">
          <use xlink:href="#icon-icon_daohang_shujutongbu"></use>
        </svg>
        <p>全连接数据同步</p>
      </div>

      <span v-if="spaceVisible" class="text-A8 mr-16px">|</span>
      <div class="item cursor-pointer" @click="handleBack">
        <p>返回首页</p>
      </div>

      <!-- 数据库备份 -->
      <BackupDialog
        :visible="backupVisible"
        @close="backupVisible = false"
        @complete="backupVisible = false"
        @isBackuping="setIsBackuping"
      />

      <!-- 离线升级 -->
      <UpgradeDialog :visible="upgradeVisible" @close="upgradeVisible = false" @isUpgrating="setIsUpgrating" />

      <!-- 安徽省平台同步 -->
      <SyncDialog :visible="syncVisible" @close="syncVisible = false" />
    </template>
  </qlHeader>
</template>

<script>
import { avaSyncSchool } from '@/api/basic';

import { mapState } from 'vuex';
import qlHeader from '@/components/header';
import BackupDialog from './backupDialog.vue';
import UpgradeDialog from './upgradeDialog.vue';
import SyncDialog from './syncDialog.vue';

export default {
  components: {
    qlHeader,
    BackupDialog,
    UpgradeDialog,
    SyncDialog,
  },

  data() {
    return {
      isBackuping: false, // 数据库备份任务 是否进行中
      isUpdating: false, // 离线升级 是否进行中

      backupVisible: false,
      upgradeVisible: false,
      syncVisible: false,
    };
  },

  computed: {
    ...mapState({
      isSchoolSuper: (state) => state.user.isSchoolSuper,
      importDataShow: (state) => state.user.importDataShow,
      mainIndexShow: (state) => state.user.mainIndexShow,
      upgradeViewStatus: (state) => state.upgrade.upgradeViewStatus,
    }),
    spaceVisible() {
      if (this.mainIndexShow && (this.isSchoolSuper || this.importDataShow)) {
        return true;
      }
      return false;
    },
  },

  methods: {
    // handle 点击数据库备份
    handleBackup() {
      if (this.isUpdating) {
        this.$message.warning('离线升级中，请稍后尝试');
        return;
      }
      this.backupVisible = true;
    },
    // emit 数据
    setIsBackuping(bool) {
      this.isBackuping = bool;
    },
    // handle 点击离线升级
    handleUpgrade() {
      if (this.isBackuping) {
        this.$message.warning('数据库备份中，请稍后尝试');
        return;
      }
      this.upgradeVisible = true;
    },
    setIsUpgrating(bool) {
      this.isUpdating = bool;
    },
    // handle 全连接数据同步
    handleAllConnect() {
      avaSyncSchool({}).then((res) => {
        if (res.status === 0) {
          this.$message.success('同步成功');
        } else {
          if (res.msg.indexOf('暂无权限') > -1) {
            this.$message.error('暂无权限');
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    // handle 返回首页
    handleBack() {
      window.location.href = `${window.location.origin}/#/home`;
    },
    // util 是否显示升级失败信息（一次性）
    async checkUpgradeResult() {
      if (this.$store.state.upgrade.isCheckUpgradeResult) {
        this.$store.dispatch('upgrade/checkUpgradeResult').then(() => {
          this.upgradeVisible = true;
        });
      }
    },
  },

  watch: {
    isSchoolSuper: {
      handler: function (val) {
        if (val) {
          // 离线升级 是否显示升级失败信息(一次性)
          this.checkUpgradeResult();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  @apply text-A10 T5;
  margin-right: 16px;

  &.cursor-pointer {
    &:hover {
      @apply text-theme;
    }
  }
}
</style>
