<template>
  <el-dialog
    custom-class="ql-dialog "
    title="数据库备份"
    width="450px"
    :visible.sync="dialogVisible"
    @close="$emit('close')"
  >
    <div v-loading="isLoading">
      <!-- 未开始 -->
      <div v-if="![STATUS_ING, STATUS_END, STATUS_FAIL_END].includes(backupStatus)" class="flex flex-col items-center">
        <p style="margin-bottom: 70px">数据库备份预计30分钟</p>
        <el-button type="primary" style="width: 120px" @click="handleBackup">开始备份</el-button>
      </div>
      <!-- 备份中 -->
      <div v-if="backupStatus === STATUS_ING" class="flex flex-col items-center">
        <p class="mg-b-10">数据库备份预计30分钟</p>
        <img src="@/assets/images/components/header/loading.gif" alt="" />
        <p>备份中...</p>
      </div>
      <!-- 备份失败 -->
      <div v-if="backupStatus === STATUS_FAIL_END" class="flex flex-col items-center">
        <svg class="mg-b-10 icon" style="font-size: 40px" aria-hidden="true">
          <use xlink:href="#icon-icon_shibai"></use>
        </svg>
        <p class="mg-b-20">数据库备份失败</p>
        <el-button type="primary" style="width: 120px" @click="handleComplete">完成</el-button>
      </div>
      <!-- 备份成功 -->
      <div v-if="backupStatus === STATUS_END" class="flex flex-col items-center">
        <svg class="mg-b-10 icon" style="font-size: 40px" aria-hidden="true">
          <use xlink:href="#icon-icon_chenggong"></use>
        </svg>
        <p class="mg-b-10">数据库备份完成</p>
        <p class="mg-b-20">建议升级行为在备份后立刻执行，以免中间未备份的数据丢失</p>
        <el-button type="primary" style="width: 120px" @click="handleComplete">完成</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { startBackup, getBackupTaskStatus } from '@/api/basic';
import { getHostUrl, checkNetworkStatus } from '@/utils/common';
import { Dialog, Button } from 'element-ui';

const STATUS_NOT_START = 0; // 未开始
const STATUS_ING = 4; // 备份中
const STATUS_FAIL_END = 5; // 备份失败
const STATUS_END = 6; // 备份成功

const hostUrl = getHostUrl();

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      // const
      STATUS_NOT_START,
      STATUS_ING,
      STATUS_FAIL_END,
      STATUS_END,

      isLoading: true,
      dialogVisible: false,

      backupStatus: STATUS_NOT_START, // 页面状态
      backupInterval: null, // 轮询定时器
    };
  },
  methods: {
    handleBackup() {
      checkNetworkStatus(
        () => {
          this.startBackup();
        },
        () => {
          this.$message.error('网络异常！');
          this.buttonLoading = false;
        }
      );
    },
    handleComplete() {
      this.clearInterval();
      this.$emit('complete');
    },
    // api 启动数据库备份
    startBackup() {
      this.isLoading = true;

      startBackup(hostUrl, {
        host: hostUrl,
      })
        .then((res) => {
          if (res.status !== 0) {
            this.$message.error(res.msg);
            return;
          }
          this.backupStatus = STATUS_ING;
          this.backupInterval = setInterval(() => {
            this.getTaskStatus();
          }, 10000);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error('网络异常');
        })
        .finally(() => (this.isLoading = false));
    },
    // api 查询数据库备份任务状态
    getTaskStatus() {
      this.isLoading = true;

      getBackupTaskStatus(hostUrl)
        .then((res) => {
          if (res.status !== 0) {
            this.$message.error(res.msg);
            return;
          }
          switch (res.code) {
            case STATUS_ING: // 备份中
              this.$emit('isBackuping', true);
              if (this.backupInterval === null) {
                this.backupInterval = setInterval(() => {
                  this.getTaskStatus();
                }, 10000);
              }
              break;
            case STATUS_FAIL_END: // 备份失败
            case STATUS_END: // 备份完成
              this.$emit('isBackuping', false);
              this.clearInterval();
              break;
            default:
              break;
          }
          this.backupStatus = res.code;
        })
        .catch((err) => {
          console.log(err);
          this.$message.error('网络异常');
        })
        .finally(() => (this.isLoading = false));
    },
    // util clearInterval
    clearInterval() {
      if (this.backupInterval !== null) {
        clearInterval(this.backupInterval);
        this.backupInterval = null;
      }
    },
  },
  beforeDestroy() {
    this.clearInterval();
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.getTaskStatus();
      } else {
        this.clearInterval();
      }
    },
  },
};
</script>
