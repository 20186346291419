<template>
  <div class="backStage">
    <Header />

    <div class="backStage-wrapper">
      <SideBar />

      <div class="backStage-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 管理页
 */
import Header from './components/header';
import SideBar from '@/components/sidebar';

export default {
  name: 'backStage',

  components: {
    Header,
    SideBar,
  },

  mounted() {
    // 检查导入状态
    this.$batchEntry.checkTask();
    //三级等保-是否已经进行过手机绑定
    // this.$tlg.check();
  },

  destroyed() {
    //销毁手机绑定弹窗
    this.$tlg.destroy();
  },
};
</script>

<style lang="scss" scoped>
.backStage {
  &-wrapper {
    display: flex;
    background: #f8f8f8;
    height: calc(100vh - 64px);
  }
  &-container {
    @apply bg-A10;
    flex: 1;
    margin: 16px;
    padding: 4px 24px 24px;
    overflow-x: auto;
    border-radius: 8px 8px 0 0;
  }

  /* todo 表格样式放到表格组件里 */
  &::v-deep {
    .el-table th {
      @apply bg-A9;
    }
  }
}
</style>
