<template>
  <el-dialog
    custom-class="ql-dialog "
    title="安徽省平台数据同步"
    width="420px"
    :visible.sync="dialogVisible"
    :modal-append-to-body="false"
    @close="$emit('close')"
  >
    <div v-loading="isLoading" class="sync">
      <!-- view: 开始同步 -->
      <div class="flex flex-col items-center" v-if="viewStatus === STATUS.READY">
        <img class="img mg-b-8" src="@/assets/images/components/header/sync.png" />
        <div v-if="showLog" class="flex">
          <p>上一次同步日志：</p>
          <a download :href="exportTaskLog" class="download-log-link">同步日志</a>
        </div>
        <div v-else>
          <p class="text-F1 leading-none">数据同步</p>
        </div>
        <el-button type="primary" style="margin-top: 32px; width: 120px" :loading="buttonLoading" @click="handleSync"
          >开始同步</el-button
        >
      </div>
      <!-- view: 同步状态 -->
      <div class="flex flex-col items-center" v-if="viewStatus === STATUS.LOAGING">
        <img class="img mg-b-16" src="@/assets/images/components/header/sync.png" />
        <div class="syncTaskArray">
          <div class="syncTaskArray-item" v-for="item in syncTaskArray" :key="item.itemType">
            <span class="syncTaskArray-item-type">{{ item.itemType }}</span>
            <span class="syncTaskArray-item-status" :class="item.itemStatusClass">{{ item.itemStatus }}</span>
            <span v-if="item.itemStatusClass === 'status_success'">
              <svg class="mg-r-10 fz-16 icon" aria-hidden="true">
                <use xlink:href="#icon-icon_chenggong"></use>
              </svg>
            </span>
            <span v-if="item.itemStatusClass === 'status_fail'">
              <svg class="mg-r-8 fz-16 icon" aria-hidden="true">
                <use xlink:href="#icon-icon_shibai"></use>
              </svg>
            </span>
            <span class="syncTaskArray-item-msg">
              {{ item.itemStatusMsg }}
            </span>
          </div>
        </div>
      </div>
      <!-- view: 同步结果 -->
      <div class="flex flex-col items-center" v-if="viewStatus === STATUS.RESULT">
        <img v-if="syncTaskStatus === 4" class="img" src="@/assets/images/components/header/sync_success.png" />
        <img v-else class="img" src="@/assets/images/components/header/sync.png" />
        <div class="syncTaskArray">
          <div class="syncTaskArray-item" v-for="item in syncTaskArray" :key="item.itemType">
            <span class="syncTaskArray-item-type">{{ item.itemType }}</span>
            <span class="syncTaskArray-item-status" :class="item.itemStatusClass">{{ item.itemStatus }}</span>
            <span v-if="item.itemStatusClass === 'status_success'">
              <svg class="mg-r-10 fz-16 icon" aria-hidden="true">
                <use xlink:href="#icon-icon_chenggong"></use>
              </svg>
            </span>
            <span v-if="item.itemStatusClass === 'status_fail'">
              <svg class="mg-r-8 fz-16 icon" aria-hidden="true">
                <use xlink:href="#icon-icon_shibai"></use>
              </svg>
            </span>
            <span class="syncTaskArray-item-msg">
              {{ item.itemStatusMsg }}
            </span>
          </div>
        </div>
        <el-button v-if="showLog" type="primary" style="width: 120px" @click="handleDownloadLog"
          >下载同步日志</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Button } from 'element-ui';
import { mapState } from 'vuex';

import { checkNetworkStatus, downloadFileByA } from '@/utils/common';
import { startSync } from '@/api/basic';

const STATUS = {
  READY: '开始同步',
  RESULT: '同步结果',
  LOAGING: '同步状态',
};

const STEP_TYPE = {
  2: '学校',
  3: '学科',
  4: '班级',
  5: '教师',
  6: '学生',
  7: '任教',
};

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      STATUS,
      isLoading: false,
      dialogVisible: false,

      syncTaskArray: [
        // {
        //   itemType: '学校',
        //   itemStatus: '同步中...',
        //   itemStatusClass: 'status-syncing',
        //   itemStatusMsg: '',
        // },
      ], // 用于显示任务状态

      asyncInterval: null, // 定时轮询
      buttonLoading: false, // 按钮加载

      taskIdUsedByDownloadLog: '', // taskId 用户下载同步日志
    };
  },
  computed: {
    ...mapState({
      syncTaskStatus: (state) => state.sync.syncTaskStatus,
      syncTaskId: (state) => state.sync.syncTaskId,
    }),
    viewStatus() {
      switch (this.syncTaskStatus) {
        case 4: // 同步中
          return STATUS.LOAGING;
        case 2: // 同步失败
        case 3: // 同步完成（部分未成功）
        case 1: // 同步完成
          return STATUS.RESULT;
        case 0: // 待同步
        case 5: // 已关闭
        default:
          return STATUS.READY;
      }
    },
    // 上一次同步日志
    exportTaskLog() {
      return `${window.$ctx}/anhuisync/exportTaskLog.do?taskId=${this.syncTaskId}`;
    },
    showLog() {
      if ([0, 1, 2, 3, 5].includes(this.syncTaskStatus)) {
        let item = this.syncTaskArray.find((x) => x.itemStatus !== 1);
        if (item) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    // handle 开始同步
    handleSync() {
      this.buttonLoading = true;

      // 启动数据同步
      checkNetworkStatus(
        () => {
          startSync({
            schoolKey: this.$store.state.user.userInfo.schoolKey,
          }).then(({ result }) => {
            this.$store.commit('sync/SET_SYNC_TASK_ID', result.taskId);
            this.taskIdUsedByDownloadLog = result.taskId;
            this.setInterval();
          });
        },
        () => {
          this.$message.error('网络异常！');
          this.buttonLoading = false;
        }
      );
    },
    // handle 下载同步日志
    handleDownloadLog() {
      checkNetworkStatus(
        () => {
          downloadFileByA(`${window.$ctx}/anhuisync/exportTaskLog.do?taskId=${this.taskIdUsedByDownloadLog}`, '', {
            target: '_blank',
          });
        },
        () => {
          this.$message.error('网络异常！');
        }
      );
    },
    // util 获取任务状态
    async getSyncStatus() {
      let result = await this.$store.dispatch('sync/getSyncStatus');
      this.setSyncTaskArray(result);
      if (result[0].status === 4) {
        this.setInterval();
      } else {
        this.$store.commit('sync/SET_SYNC_TASK_ID', '');
        this.clearInterval();
      }
    },
    // util syncTaskArray
    setSyncTaskArray(data) {
      this.syncTaskArray = [];

      for (let i = 1; i < data.length; i++) {
        let itemType = STEP_TYPE[data[i].type];
        let itemStatus = '';
        let itemStatusClass = '';
        let itemStatusMsg = '';

        switch (data[i].status) {
          case 0:
            // 刚开始同步任务时，对学校设置同步中状态，为了让用户看了心理舒服点
            if (data[i].type == 2) {
              itemStatus = '同步中...';
              itemStatusClass = 'status-syncing';
            } else {
              itemStatus = '待同步';
              itemStatusClass = 'status-ready';
            }
            break;
          case 1:
            itemStatus = '同步完成';
            itemStatusClass = 'status_success';
            break;
          case 2:
            itemStatus = '同步失败';
            itemStatusClass = 'status_fail';
            break;
          case 3:
            itemStatus = '同步完成';
            itemStatusClass = 'status_success';
            itemStatusMsg = '部分信息未同步成功，请查看日志';
            break;
          case 4:
            itemStatus = '同步中...';
            itemStatusClass = 'status_syncing';
            break;
        }

        this.syncTaskArray.push({
          status: data[i].status,
          itemType,
          itemStatus,
          itemStatusClass,
          itemStatusMsg,
        });
      }
    },
    // util interval
    setInterval() {
      if (!this.asyncInterval) {
        this.asyncInterval = setInterval(() => {
          this.getSyncStatus();
        }, 10000);
      }
    },
    // util interval
    clearInterval() {
      if (this.asyncInterval) {
        clearInterval(this.asyncInterval);
        this.asyncInterval = null;
      }
    },
  },
  destroyed() {
    this.clearInterval();
  },
  watch: {
    visible: {
      handler: async function (val) {
        if (val) {
          // reset
          Object.assign(this.$data, this.$options.data());
          this.$store.commit('sync/SET_SYNC_TASK_STATUS', null);

          this.dialogVisible = true;

          if (this.syncTaskId) {
            let result = await this.$store.dispatch('sync/getNewestTask');
            this.setSyncTaskArray(result);
            if (result[0].status === 4) {
              this.setInterval();
            }
          }
        } else {
          this.clearInterval();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sync {
  .download-log-link {
    @apply text-theme;
  }

  .img {
    width: 100px;
    height: 100px;
  }

  .syncTaskArray {
    display: flex;
    flex-direction: column;
    margin-bottom: 27px;

    .syncTaskArray-item {
      display: flex;
      line-height: 22px;
      align-items: center;
      margin-bottom: 13px;

      .syncTaskArray-item-type {
        font-size: 13px;
        font-weight: bold;
        color: #666666;
        margin-right: 8px;
      }
      .syncTaskArray-item-status {
        font-size: 13px;
        font-weight: 400;
        margin-right: 8px;

        &.status_success {
          @apply text-theme;
        }
        &.status_fail {
          color: #ff6565;
        }
        &.status-syncing {
          color: #666666;
        }
        &.status-ready {
          color: #b2b2b2;
        }
      }
      .syncTaskArray-item-msg {
        font-size: 12px;
        font-weight: 400;
        width: 180px;
        color: #ff872f;
      }
    }
  }
}
</style>
