<template>
  <el-dialog
    title="离线升级"
    width="600px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-loading="isLoading" class="upgrade-offline">
      <!-- view: 未开始 -->
      <div v-if="upgradeViewStatus === VIEW_STATUS.NOT_START" class="flex flex-col items-center">
        <div class="flex flex-col">
          <div class="item">
            <span class="item-title">当前版本</span>
            <span class="item-text">{{ info.versionName }}</span>
          </div>
          <div class="item">
            <span class="item-title">升级描述</span>
            <span class="item-text">{{ info.description }}</span>
          </div>
          <div class="item">
            <span class="item-title">上一次更新时间</span>
            <span class="item-text">{{ info.upgradeTime }}</span>
          </div>
          <div v-if="info.backupTime" class="item">
            <span class="item-title">上一次备份时间</span>
            <span class="item-text">{{ info.backupTime }}</span>
          </div>
          <div v-if="info.backupTime" class="item">
            <span class="item-title"></span>
            <div class="item-tip">建议升级行为在备份后立刻执行，以免中间未备份的数据丢失</div>
          </div>
          <div v-if="info.showPrevLog" class="item">
            <span class="item-title">上一次升级日志</span>
            <span class="item-download" @click="handleDownloadLog">点击下载</span>
          </div>
          <div class="item items-center mg-t-20" style="padding-left: 115px">
            <input ref="file" type="file" hidden accept=".gz" multiple="false" @change="uploadFile($event, 1)" />
            <el-button type="primary" @click="handleUpgrade">离线升级</el-button>
            <el-checkbox class="mg-l-24" v-model="isBackup">若升级失败使用已备份的数据库进行回滚</el-checkbox>
          </div>
        </div>
      </div>
      <!-- view: 加载中 -->
      <div v-if="upgradeViewStatus === VIEW_STATUS.LOAGING" class="flex flex-col items-center">
        <img src="@/assets/images/components/header/loading.gif" alt="" />
        <p>{{ statusText }}</p>
      </div>
      <!-- view: 失败 -->
      <div v-if="upgradeViewStatus === VIEW_STATUS.FAIL" class="flex flex-col items-center">
        <svg class="mg-b-10 icon" style="font-size: 40px" aria-hidden="true">
          <use xlink:href="#icon-icon_shibai"></use>
        </svg>
        <p v-if="[3, 5].includes(upgradeCode)">{{ statusText }}</p>
        <p v-if="upgradeCode === 9" class="mg-b-20">升级失败，请下载升级日志查看失败原因</p>
        <p v-if="upgradeCode === 11" class="mg-b-20">升级失败，已自动回滚至升级前版本。请下载升级日志查看失败原因</p>
        <el-button type="primary" style="width: 120px" @click="handleDownloadLog">下载升级日志</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog, Button, Checkbox } from 'element-ui';
import { mapState } from 'vuex';

import {
  getCurrentVersion,
  getLastBackupTime,
  getUpgradeTaskStatus,
  setUpgradeStatus,
  uploadUpgradeFile,
} from '@/api/basic';
import { getHostUrl, checkNetworkStatus, downloadFileByA } from '@/utils/common';

import { VIEW_STATUS } from '@/store/modules/upgrade.js';

const STATUS_TEXT = [
  {
    code: 1,
    msg: '正在升级中...解压升级包',
  },
  {
    code: 2,
    msg: '正在升级中...备份配置文件',
  },
  {
    code: 3,
    msg: '正在升级中...备份配置文件失败',
  },
  {
    code: 4,
    msg: '正在升级中...备份数据库',
  },
  {
    code: 5,
    msg: '正在升级中...备份数据库失败',
  },
  {
    code: 6,
    msg: '正在升级中...备份数据库成功',
  },
  {
    code: 7,
    msg: '正在升级中...执行升级程序',
  },
  {
    code: 8,
    msg: '升级成功！重启中...',
  },
  {
    code: 9,
    msg: '升级失败！',
  },
  {
    code: 10,
    msg: '升级失败！还原中...',
  },
  {
    code: 11,
    msg: '升级失败！还原完成, 重启中...',
  },
];

const hostUrl = getHostUrl();

let upgradeInterval; // 加载 定时轮询
let restartInterval; // 重启 定时轮询
let restartTimeout; // 重启 延时器

export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      // const
      VIEW_STATUS,

      isLoading: false,
      dialogVisible: false,

      info: {
        versionName: '', // 当前版本
        description: '', // 升级描述
        upgradeTime: '', // 上一次更新时间
        backupTime: '', // 上一次备份时间
        showPrevLog: false, // 上一次升级日志
      },
      isBackup: true,
    };
  },
  computed: {
    ...mapState({
      upgradeViewStatus: (state) => state.upgrade.upgradeViewStatus,
      upgradeCode: (state) => state.upgrade.upgradeCode,
      isUpgrade: (state) => state.upgrade.isUpgrade,
      isRestart: (state) => state.upgrade.isRestart,
      isCheckUpgradeResult: (state) => state.upgrade.isCheckUpgradeResult,
    }),
    statusText() {
      if (this.upgradeCode === undefined) {
        return '';
      } else {
        let item = STATUS_TEXT.find((x) => x.code === this.upgradeCode);

        return item ? item.msg : '';
      }
    },
  },
  methods: {
    // handle 下载升级日志
    handleDownloadLog() {
      checkNetworkStatus(
        () => {
          downloadFileByA(`${hostUrl}/failLog`);
        },
        () => {
          this.$message.error('网络异常！');
          this.buttonLoading = false;
        }
      );
    },
    // handle 点击升级
    handleUpgrade() {
      this.$refs.file.click();
    },
    // handle 上传文件
    uploadFile(e) {
      const file = e.target.files[0];
      let fileNameArray = file.name.split('.');
      let fileType = fileNameArray[fileNameArray.length - 1];
      let reg = /#|%|&/g;

      const checkFile = () => {
        if (fileType.indexOf('gz') === -1) {
          this.$message.error('请选择.gz格式的文件');
          return false;
        }
        if (reg.test(file.name)) {
          this.$message.error('文件名不能存在非法字符');
          return false;
        }
        return true;
      };

      if (!checkFile()) {
        return;
      }

      checkNetworkStatus(
        () => {
          this.isLoading = true;

          setUpgradeStatus(hostUrl)
            .then((res) => {
              return new Promise((resolve, reject) => {
                if (res.status !== 0) {
                  reject(res.msg);
                  return;
                }
                resolve();
              });
            })
            .then(() => {
              let data = new FormData();
              data.append('file', file);
              data.append('host', hostUrl);
              data.append('backup', this.isBackup ? 1 : 0);

              return uploadUpgradeFile(hostUrl, data);
            })
            .then((res) => {
              return new Promise((resolve, reject) => {
                if (res.status !== 0) {
                  reject(res.msg);
                  return;
                }
                this.getUpgradeStatus();
                resolve();
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error('网络异常');
            })
            .finally(() => {
              this.isLoading = false;
            });
        },
        () => {
          this.$message.error('网络异常！');
          this.buttonLoading = false;
        }
      );
    },
    // api 查询当前版本信息
    getCurrentVersion() {
      this.isLoading = true;
      return getCurrentVersion(hostUrl)
        .then((res) => {
          if (res.status !== 0) {
            this.$message.error(res.msg);
            return;
          }
          this.info.versionName = res.result.versionName;
          this.info.description = res.result.description;
          this.info.upgradeTime = res.result.upgradeTime;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // api 上一次备份时间
    getLastBackupTime() {
      this.isLoading = true;
      return getLastBackupTime(hostUrl)
        .then((res) => {
          if (res.status !== 0) {
            this.$message.error(res.msg);
            return;
          }
          this.info.backupTime = res.data;
          return res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // api 上一次的升级日志
    judgeHasPrevLog() {
      this.isLoading = true;
      return getUpgradeTaskStatus(hostUrl)
        .then((res) => {
          if (res.status !== 0) {
            this.$message.error(res.msg);
            return;
          }
          if ([3, 5, 9, 11].includes(res.code)) {
            this.info.showPrevLog = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // util 获取离线升级任务状态
    async getUpgradeStatus() {
      let code = await this.$store.dispatch('upgrade/getUpgradeStatus');

      switch (code) {
        case 1:
        case 2:
        case 4:
        case 6:
        case 7:
        case 10:
          // 加载中
          if (!upgradeInterval) {
            upgradeInterval = setInterval(() => {
              this.getUpgradeStatus();
            }, 10000);
          }
          break;
        case 3:
        case 5:
        case 9:
          // 升级失败
          if (!upgradeInterval) {
            clearInterval(upgradeInterval);
          }
          break;
        case 8: // 升级成功 重启中
        case 11: // 升级失败,还原完成 重启中
          // 升级成功、升级失败还原，执行重启，180秒后查询重启状态
          if (upgradeInterval) {
            clearInterval(upgradeInterval);
          }
          restartTimeout = setTimeout(() => {
            if (!restartInterval) {
              this.getRestartStatus();
            }
            clearTimeout(restartTimeout);
          }, 60000);
          break;
        default:
          break;
      }
      return code;
    },
    // util 获取重启状态
    getRestartStatus() {
      restartInterval = setInterval(() => {
        this.$store
          .dispatch('upgrade/getRestartStatus')
          .then(async () => {
            // 重启完成
            this.$store.commit('upgrade/SET_IS_RESTART', '');
            this.$store.commit('upgrade/SET_SHOW_UPGRADE_STATUS', true);
            clearInterval(restartInterval);
            await this.$store.dispatch('user/resetToken');
            this.$router.replace('/login');
          })
          .catch(() => {
            // 还在重启
          });
      }, 10000);
    },
    async init() {
      // reset
      this.$store.commit('upgrade/SET_UPGRADE_STATUS', VIEW_STATUS.NOT_START);
      this.$store.commit('upgrade/SET_UPGRADE_CODE', undefined);

      if (this.isUpgrade) {
        // 是否升级中
        await this.getUpgradeStatus();
      }
      if (this.isRestart) {
        // 是否重启中
        await this.$store.dispatch('upgrade/getUpgradeStatus');
        this.getRestartStatus();
      }

      if (this.upgradeViewStatus === VIEW_STATUS.NOT_START) {
        await this.getCurrentVersion();
        let time = await this.getLastBackupTime();
        if (time) {
          this.judgeHasPrevLog();
        }
      }
    },
    // 清除页面定时器
    clear() {
      if (upgradeInterval) {
        clearInterval(upgradeInterval);
      }
      if (restartInterval) {
        clearInterval(restartInterval);
      }
      if (restartTimeout) {
        clearTimeout(restartTimeout);
      }
    },
  },
  beforeDestroy() {
    this.clear();
  },
  watch: {
    visible(val) {
      if (val) {
        // reset
        Object.assign(this.$data, this.$options.data());

        this.init();
      } else {
        this.clear();
      }

      this.dialogVisible = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.upgrade-offline {
  .item {
    display: flex;
    margin-bottom: 10px;

    .item-title {
      display: flex;
      flex-direction: row-reverse;
      flex-shrink: 0;
      width: 100px;
      margin-right: 20px;
      color: #444444;
    }
    .item-text {
      font-size: 14px;
      font-weight: 400;
      color: #444444;
      line-height: 20px;
    }
    .item-tip {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    .item-download {
      @apply text-theme;
      cursor: pointer;
    }
  }
}
</style>
